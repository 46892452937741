.bounce {
    outline: 0;
    animation-name: bounce;
    animation-duration: 0.5s;
    animation-delay: 0.15s;
    box-shadow: 0px 5px 5px -3px rgba(255, 0, 0, 0.2), 0px 8px 10px 1px rgba(255, 0, 0, 0.14), 0px 3px 14px 2px rgba(255, 0, 0, 0.12);
}

/* This approximates the ease-in-out-bounce animation from easings.net, which would require a plug-in to use*/
@keyframes bounce {
    0% {
        transform: translateX(0px);
        timing-function: ease-in;
    }
    37% {
        transform: translateX(10px);
        timing-function: ease-out;
    }
    55% {
        transform: translateX(-10px);
        timing-function: ease-in;
    }
    73% {
        transform: translateX(8px);
        timing-function: ease-out;
    }
    82% {
        transform: translateX(-8px);
        timing-function: ease-in;
    }
    91% {
        transform: translateX(4px);
        timing-function: ease-out;
    }
    96% {
        transform: translateX(-4px);
        timing-function: ease-in;
    }
    100% {
        transform: translateX(0px);
        timing-function: ease-in;
    }
}

.e-de-background {
    background-color: rgb(180, 180, 180) !important; /* You can customize this color*/
}

.e-de-ctnr-variable:before {
    content: "\e350";
    font-size: 20px;
}
.e-de-ctnr-condition:before {
    content: "\e19b";
    font-size: 20px;
}
.e-de-ctnr-repetition:before {
    content: "\e412";
    font-size: 20px;
}

.e-de-ctnr-listVar:before {
    content: "\e345";
    font-size: 20px;
}

.e-de-ctnr-toolbar {
    transform: scale(0.86) !important;
    width: 105% !important;
    left: -6% !important;
    margin-right: -6% !important;
    position: relative !important;
}

.e-de-prop-toolCustom button {
    background-color: silver !important;
    color: grey !important;
    cursor: default !important;
}

.e-de-prop-toolCustom button:hover {
    background-color: silver !important;
}

.tooltip-green button {
    background-color: #43af6c !important;
    transition: 0.2s !important;
    cursor: pointer !important;
}

.tooltip-green button:hover {
    background-color: #72c391 !important;
}

.tooltip-red button {
    transition: 0.2s !important;
    cursor: pointer !important;

    background-color: #fb3b34 !important;
}

.tooltip-red button:hover {
    background-color: #f6726e !important;
}

.tooltip-blue button {
    transition: 0.2s !important;
    cursor: pointer !important;

    background-color: #54a4ff !important;
}

.tooltip-blue button:hover {
    background-color: #a6d2ff !important;
}

.e-de-prop-toolListVar span {
    line-height: 1 !important;
    color: grey !important;
}

.e-de-prop-toolListVar .e-tbar-btn-text {
    font-weight: bold !important;
}

.e-de-prop-toolCustom span {
    line-height: 1 !important;
    color: white !important;
}

.e-de-prop-toolCustom .e-tbar-btn-text {
    font-weight: bold !important;
}

.e-de-prop-toolCustom {
    margin-right: 15px !important;
    margin-left: 15px !important;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
    max-width: 130px;
}

.e-de-statusbar-pageweb {
    display: none !important;
}

.e-de-showhide-btn {
    display: none !important;
}

.disablePane {
    display: none !important;
}

.hideEditor {
    display: none;
}
#editor_document {
    width: 100%;
}
